import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-list-radios',
  templateUrl: './list-radios.component.html',
  styleUrls: ['./list-radios.component.scss']
})
export class ListRadiosComponent implements OnInit {

  description="aaron"
  currentOption: string="";
  title="";
  options: any[] = [];
  
  acceptButton:string  = "";
  cancelButton:string  = "";

  constructor(private dialogRef: MatDialogRef<ListRadiosComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 

      const { title, options, defaultOption, acceptButton, cancelButton, description } = data;
      this.description = description;
      this.currentOption = defaultOption;
      this.acceptButton = acceptButton;
      this.cancelButton = cancelButton;
      this.title = title;
      this.options = options;
    }

  ngOnInit(): void {
  }


  save() {
    this.dialogRef.close(this.currentOption);
  } 

  close() {
      this.dialogRef.close();
  }

}

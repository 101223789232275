
export const COUNTRIES = [
  {
    "name":"Belize",
    "alpha2Code":"BZ",
    "alpha3Code":"BLZ",
    "code":"501",
    "capital":"Belmopan",
    "nativeName":"Belize",
    "numericCode":"084",
    "img": "Belize.png",
    "pk_code": 22
  },
  {
    "name":"Costa Rica",
    "alpha2Code":"CR",
    "alpha3Code":"CRI",
    "code":"506",
    "capital":"San José",
    "nativeName":"Costa Rica",
    "numericCode":"188",
    "img": "costa-rica.png",
    "pk_code": 52
  },
  {
    "name":"El Salvador",
    "alpha2Code":"SV",
    "alpha3Code":"SLV",
    "code":"503",
    "capital":"San Salvador",
    "nativeName":"El Salvador",
    "numericCode":"222",
    "img": "el-salvador.png",
    "pk_code": 65
  },
  {
    "name":"Guatemala",
    "alpha2Code":"GT",
    "alpha3Code":"GTM",
    "code":"502",
    "capital":"Guatemala City",
    "nativeName":"Guatemala",
    "numericCode":"320",
    "img": "guatemala.png",
    "pk_code": 90
  },
  {
    "name":"Honduras",
    "alpha2Code":"HN",
    "alpha3Code":"HND",
    "code":"504",
    "capital":"Tegucigalpa",
    "nativeName":"Honduras",
    "numericCode":"340",
    "img": "honduras.png",
    "pk_code": 97
  },
  {
    "name":"Nicaragua",
    "alpha2Code":"NI",
    "alpha3Code":"NIC",
    "code":"505",
    "capital":"Managua",
    "nativeName":"Nicaragua",
    "numericCode":"558",
    "img": "nicaragua.png",
    "pk_code": 158
  },
  {
    "name":"Panama",
    "alpha2Code":"PA",
    "alpha3Code":"PAN",
    "callingCodes":"507",
    "capital":"Panama City",
    "nativeName":"Panamá",
    "numericCode":"591",
    "img": "panama.png",
    "pk_code": 169
  }
];

import { Injectable } from '@angular/core';

@Injectable()
export class SetDateStringService {

  monthNames: Array<string> = [];
  shortDays: Array<string> = [];
  longDays: Array<string> = [];

  constructor(){}

  setDateString(date: Date, format: number, separator: string): string {
    switch(format){
      case 1: return `${('0'+date.getDate()).slice(-2)}${separator}${('0'+(date.getMonth()+1)).slice(-2)}${separator}${date.getFullYear()}`;
      case 2: return `${('0'+(date.getMonth()+1)).slice(-2)}${separator}${('0'+date.getDate()).slice(-2)}${separator}${date.getFullYear()}`;
      case 3: return `${date.getFullYear()}${separator}${('0'+(date.getMonth()+1)).slice(-2)}${separator}${('0'+date.getDate()).slice(-2)}`;
      case 4: return `${('0'+date.getDate()).slice(-2)}${separator}${this.monthNames[date.getMonth()]}${separator}${date.getFullYear()}`;
      case 5: return `${('0'+date.getDate()).slice(-2)}${separator}${('0'+(date.getMonth()+1)).slice(-2)}${separator}${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}`;
      case 6: return `${('0'+(date.getMonth()+1)).slice(-2)}${separator}${date.getFullYear()}`;
    }
  }

  setDateStringToString(dateString: string, format: number, separatorSplit: string, separator: string): string {
    const dateSplit = dateString.split(separatorSplit);
    switch(format){
      case 1: return `${dateSplit[2]}${separator}${dateSplit[0]}${separator}${dateSplit[1]}`;
      case 2: return `${dateSplit[2]}${separator}${dateSplit[1]}${separator}${dateSplit[0]}`;
      case 3: return `${dateSplit[1]}${separator}${dateSplit[2]}${separator}${dateSplit[0]}`;
      case 4: return `${dateSplit[1]}${separator}${dateSplit[0]}${separator}${dateSplit[2]}`;
      case 5: return `${dateSplit[0]}${separator}${dateSplit[2]}${separator}${dateSplit[1]}`;
      case 6: return `${dateSplit[0]}${separator}${dateSplit[1]}${separator}${dateSplit[2]}`;
      case 7: {
        const date = new Date(dateString);
        if(!!separatorSplit){
          date.setHours(separatorSplit === '+' ? date.getHours() + 6 : date.getHours() - 6);
        }
        return `${('0'+date.getDate()).slice(-2)}${separator}${('0'+(date.getMonth()+1)).slice(-2)}${separator}${date.getFullYear()}`;
      }
      case 8: {
        const date = new Date(dateString);
        if(!!separatorSplit){
          date.setHours(separatorSplit === '+' ? date.getHours() + 6 : date.getHours() - 6);
        }
        return `${('0'+date.getDate()).slice(-2)}${separator}${('0'+(date.getMonth()+1)).slice(-2)}${separator}${date.getFullYear()} ${this.setDateToTimeString(date, 2, ':')}`;
      }
    }
  }

  setStringDate(dateString: string, format: number, separatorSplit: string, dateDate?: Date, time?: string): Date {
    const dateSplit = !dateDate && dateString.split(separatorSplit);
    switch(format){
      case 1: return new Date(Number(dateSplit[0]), (Number(dateSplit[1]) -1), Number(dateSplit[2]));
      case 2: return new Date(Number(dateSplit[2]), (Number(dateSplit[1]) -1), Number(dateSplit[0]));
      case 3: return new Date(Number(dateSplit[2]), (Number(dateSplit[0]) -1), Number(dateSplit[1]));
      case 4: {
        const addSplit = this.setTimeString(time, 3, ':');
        return new Date(dateDate.getFullYear(), dateDate.getMonth(), dateDate.getDate(), Number(addSplit.split(':')[0]), Number(addSplit.split(':')[1]));
      }
      case 5: {
        const date = new Date(dateString);
        if(!!separatorSplit){
          date.setHours(separatorSplit === '+' ? date.getHours() + 6 : date.getHours() - 6);
        }
        return date;
      }
    }
  }

  setTimeString(time: string, format: number, separator: string, ampmOnly?: boolean, ampm?: string): string {
    if(!!time){
      const timeSplit = time.split(':');
      switch(format) {
        case 1: return `${timeSplit[0]}${separator}${timeSplit[1]}`;
        case 2: return this.setDateAMPM(timeSplit, separator, ampmOnly, ampm);
        case 3: {
          // LLEGA: 10:50 PM / 10:50 AM
          // RESP: 22:50 / 10:50
          const spl1 = time.split(' ')[0].split(':');
          return this.setTimePlus12(time, spl1);
        }
        case 4: return `${('0'+timeSplit[0]).slice(-2)}${separator}${timeSplit[1]}`;
      }
    }

    return '';
  }

  setDateToTimeString(date: Date, format: number, separator: string, ampm?: string): string {
    switch(format){
      case 1: return `${('0'+date.getHours()).slice(-2)}${separator}${('0'+date.getMinutes()).slice(-2)}`;
      case 2: return this.setTimeString(`${('0'+date.getHours()).slice(-2)}${separator}${('0'+date.getMinutes()).slice(-2)}`, 2, separator, true, ampm);
      case 3: return this.getAMPMByVar(date, ampm);
    }
  }

  setDateAMPM(dte: any, separator: string, ampmOnly: boolean, ampm: string): string {
    if(Number(dte[0]) >= 12){
      if(dte[0] === '12')
        return `${dte[0]}${separator}${dte[1]} ${this.getAMPMMD(ampmOnly, ampm, 'pm', 'MD')}`;
      else
        return `${('0'+(Number(dte[0])-12)).slice(-2)}${separator}${dte[1]} ${this.getAMPMMD(ampmOnly, ampm, 'pm', 'PM')}`;
    }
    else{
      if(Number(dte[0]) === 0){
        return `12${separator}${dte[1]} ${this.getAMPMMD(ampmOnly, ampm, 'am', 'AM')}`;
      }
      else{
        return `${dte[0]}${separator}${dte[1]} ${this.getAMPMMD(ampmOnly, ampm, 'am', 'AM')}`;
      }
    }
  }

  getAMPMMD(ampmOnly: boolean, ampm: string, ampmMin: string, ampmMay: string): string {
    if(!ampm)
      return !!ampmOnly ? ampmMin : ampmMay;
    return ampm;
  }

  setTimePlus12(time, timeSplit): string {
    let hour = timeSplit[0];
    if(time.toLowerCase().includes('pm') || time.toLowerCase().includes('md')){
      if(timeSplit[0] !== '12')
        hour = (Number(timeSplit[0])+12);
    }
    else if(timeSplit[0] === '12')
      hour = '00';

    return `${hour}:${('0'+timeSplit[1]).slice(-2)}`;
  }

  getAMPMByVar(datetime: Date, time: string): string {
    let hour = datetime.getHours(), timeString;
    // Si es mayor a 12 y es am
    hour = (Number(hour) >= 12 && time === 'am')? Number(hour) - 12 : hour;
    // Si es menor a 12 y es pm
    hour = (Number(hour) < 12 && time === 'pm')? Number(hour) + 12 : hour;
    // if 00 then it is 12 am
    timeString = `${('0' + hour).slice(-2)}:${('0' + datetime.getMinutes()).slice(-2)}`;

    return timeString;
  }

  getAMPM(date: Date): string {
    const hour = Number(('0' + date.getHours()).slice(-2));
    let ampm = 'AM';
    if(hour >= 12){
      ampm = (hour === 12) ? 'MD' : 'PM';
    }

    return ampm;
  }

  setFormatCalendar(datePickerElement, monthYear: boolean = false): void {
    datePickerElement['_datepickerInput']._dateFormats = {
      parse: {
        dateInput: 'LL',
      },
      display: {
        dateA11yLabel: {year: 'numeric', ...(monthYear && {month: 'long'})},
        dateInput: {year: 'numeric', ...(monthYear && {month: 'numeric'})},
        monthYearA11yLabel: {year: 'numeric', ...(monthYear && {month: 'long'})},
        monthYearLabel: {year: 'numeric', ...(monthYear && {month: 'short'})}
      }
    };
  }

  setTime0(date: Date): Date {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }
}

import { FormControl, FormGroup } from '@angular/forms';

export function getDataObject(object: any, param: string, secondParam: string, defaultReturn: any): any {
  try{
    if(object){
      return (!secondParam ? object[param] : object[param][secondParam]);
    }
    else return defaultReturn;
  } catch(_){
    return defaultReturn;
  }
}

export function gotToNewTab(url): void {
  window.open(url, '_blank').focus();
}

export function deleteStressM(stg: string): string {
  return stg
    .normalize('NFD')
    .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,'$1')
    .normalize();
}

export function setImage(item): string {
  switch(item.toLowerCase()){
    case 'visa' : return 'fab fa-cc-visa';
    case 'master' : return 'fab fa-cc-mastercard';
    case 'discover' : return 'fab fa-cc-discover';
    case 'amex' : return 'fab fa-cc-amex';
    case 'american_express' : return 'fab fa-cc-amex';
    case 'dinners' : return 'fab fa-cc-diners-club';
    case 'jcb' : return 'fab fa-cc-jcb';
    default: return 'fas fa-credit-card';
  }
}

export const YesNo = {
  yes: true,
  no: false
};

export function onDigitInput(event: any, numberControl: number, accountForm: FormGroup, idControl: string): any {
  let element;
  const nextPin = numberControl < 4 ? numberControl + 1 : numberControl,
        prevPin = numberControl > 1 ? numberControl - 1 : numberControl;
  if (event.code !== "Backspace" && parseInt(event.key) >= 0) {
    if (numberControl < 4) {
      accountForm.get(`${idControl}${numberControl}Control`).disable();
    }
    accountForm.get(`${idControl}${nextPin}Control`).enable();
    element = event.srcElement.nextElementSibling;
  }
  if (event.code === "Backspace") {
    accountForm.get(`${idControl}${numberControl}Control`).disable();
    accountForm.get(`${idControl}${nextPin}Control`).disable();
    accountForm.get(`${idControl}${prevPin}Control`).enable();
    element = event.srcElement.previousElementSibling;
  }

  if (element == null) return;
  else element.focus();
}

export function validControlDisabled(control: FormControl, required: boolean, minLength?: number, maxLength?: number, patter?: any): boolean {
  let valid = control.valid;
  let [requiredValid, minLengthValid, maxLengthValid, patterValid] = [true, true, true, true];
  if (control.disabled) {
    if (required) {
      requiredValid = !!control.value;
    }
    if (minLength) {
      minLengthValid = `${control.value}`.length >= minLength;
    }
    if (maxLength) {
      maxLengthValid = `${control.value}`.length <= maxLength;
    }
    if (patter) {
      patterValid = !!`${control.value}`.match(patter);
    }

    return requiredValid && minLengthValid && maxLengthValid && patterValid;
  }

  return valid;
}
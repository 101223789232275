import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-rounded-image-selector',
  templateUrl: './rounded-image-selector.component.html',
  styleUrls: ['./rounded-image-selector.component.scss'],
})
export class RoundedImageSelectorComponent {

  @Input() src: string;
  @Input() profilePicture : string;
  @Output() OnChange = new EventEmitter<any>();
  @Output() errorEmit = new EventEmitter<any>();

  onFileSelected(event: any): void {
    const selectedFile = event.target.files[0], reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result;
      if (e.total <= 10000000) { // 10 Mb
        img.onload = () => {
          // if (img.height < 100 || img.width < 100) {
          //   const { profile: { alerts } } = this.sessionData.languageMessage;
          //   this.snackbar.showSnackBar('', `${alerts.photo}${this.width}x${this.height}${alerts.pixels}`);
          // }
          // else{
            this.OnChange.emit(e.target.result);
          // }
        };
      } else {
        this.errorEmit.emit();
      }
    };

    reader.readAsDataURL(selectedFile);
  }
}

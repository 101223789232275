import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
// tslint:disable-next-line: import-spacing
import { TranslateService }   from '@ngx-translate/core';
import { ApiService } from 'src/api/api.service';
import { ApiServiceResponse } from 'src/api/api.service.response';
import { SEND_LINK } from 'src/api/constants';
import { General } from 'src/data/general';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  translate: TranslateService;
  storee: number;
  general = General;
  showAppLinks = false;

  constructor(private router: Router, translate: TranslateService, public api: ApiService, private route: ActivatedRoute) {
    this.translate = translate;
    this.lang = this.translate.currentLang;

    /*
    setTimeout(() => {
      const script = document.createElement('script'), sop = document.getElementById('soportt');
      script.src = 'https://sealserver.trustwave.com/seal.js?code=c14053eb02734db9a6a6c596c31615de';
      script.type = 'text/javascript';
      sop.appendChild(script);
    }, 800); */
  }

  countries = [
    { code: '+54', name: 'Argentina', img: 'argentina.png' },
    { code: '+501', name: 'Belice', img: 'Belize.png' },
    { code: '+56', name: 'Chile', img: 'Chile.png' },
    { code: '+57', name: 'Colombia', img: 'colombia.png' },
    { code: '+506', name: 'Costa Rica', img: 'costa-rica.png' },
    { code: '+503', name: 'El Salvador', img: 'el-salvador.png' },
    { code: '+502', name: 'Guatemala', img: 'guatemala.png' },
    { code: '+504', name: 'Honduras', img: 'honduras.png' },
    { code: '+52', name: 'México', img: 'mexico.png' },
    { code: '+505', name: 'Nicaragua', img: 'nicaragua.png' },
    { code: '+507', name: 'Panamá', img: 'panama.png' },
    { code: '+595', name: 'Paraguay', img: 'paraguay.png' },
    { code: '+51', name: 'Perú', img: 'peru.png' },
    { code: '+598', name: 'Uruguay', img: 'uruguay.png' },
    { code: '+58', name: 'Venezuela', img: 'venezuela.png' }
  ];
  visibleCodes: boolean;
  selectedValue: any;
  contry: any;

  notFound: boolean;
  active: number;
  chevron: string[];
  lang = 'es';
  message: string;

  ngOnInit() {
    this.notFound = (this.router.url.includes('404'));
    this.contry = this.countries[4];
    this.visibleCodes = false;
    this.chevron = ['arrow_drop_down', 'arrow_drop_down', 'arrow_drop_down', 'arrow_drop_down'];
    
    this.router.events.subscribe(event => {
      if(event instanceof NavigationStart){
        this.showAppLinks = (event.url.indexOf("completeAccount") == -1);
      }
    });
  }

  selectCode(contry: any) {
    this.contry = contry;
    this.visibleCodes = false;
  }

  showCodes() {
    this.visibleCodes = true;
  }

  isActived(active) {
    return this.active === active;
  }

  setActived(active) {
    this.active = (this.active !== active) ? active : -1;
    this.chevron[active] = (this.chevron[active] !== 'arrow_drop_up') ? 'arrow_drop_up' : 'arrow_drop_down';
  }

  changeLang() {
    const html = document.querySelector('html');
    html.setAttribute('lang' , this.lang || 'es');
    this.translate.use(this.lang);
  }

  signUp() {
    const c_event = new CustomEvent('signUp', {detail: 3});
    this.general.dispatchEvent(c_event);
  }

  chPage(pg, scroll) {
    this.router.navigate(['/' + pg]);
    setTimeout(() => {
      this.general.scroll(0, 0);
      setTimeout(() => {
        const section = document.getElementById(scroll);
        section.scrollIntoView({behavior: 'smooth'});
      }, 300);
    }, 1000);
  }

  numberOnly(event) {
    return ( event.ctrlKey || event.altKey
      || (47 < event.keyCode && event.keyCode < 58 && event.shiftKey === false)
      || (95 < event.keyCode && event.keyCode < 106)
      || (event.keyCode === 8) || (event.keyCode === 9)
      || (event.keyCode > 34 && event.keyCode < 40)
      || (event.keyCode === 46) );
  }

  showSnackMessage(message) {
    this.message = message;
    const snackbar = document.getElementById('snackbar');
    snackbar.className = 'show';
    setTimeout(function () {
      snackbar.className = snackbar.className.replace('show', '');
    }, 3000);
  }

  sendLink() {
    if (this.storee > 0 && this.contry && this.selectedValue) {
      this.api.post<ApiServiceResponse>(SEND_LINK, {
        query: 'sendAppLinkSms',
        platform: this.storee === 1 ? 'ios' : 'android',
        phone: `${this.contry.code}${this.selectedValue}`
      }).then(
        (response: ApiServiceResponse) => {
          this.showSnackMessage('information.succSend');
          this.storee = 0;
          this.selectedValue = '';
          this.contry = this.countries[4];
        },
        (response: ApiServiceResponse) => {
          this.showSnackMessage('information.errSend');
        }
      );
    } else {
      this.showSnackMessage('home.intro.err_send');
    }
  }
}

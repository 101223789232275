import {environment} from '../environments/environment';

export const BASE_URL = environment.serverUrlAdmin;
export const BASE_URL_API = environment.serverUrlPasser;
export const BASE_CATALOG_API = environment.catalogUrlPasser;
export const BASE_IDP_API = environment.idpUrlPasser;

// console.log('URL: ' + BASE_URL + ' - ' + process.env.NODE_ENV);
export const SET_ACCOUNT_PROFILE = BASE_URL + 'account/SetAccountProfile';
export const SET_EMPLOYEE_ACCOUNT_PROFILE = BASE_URL + 'system/SetEmployeeAccountProfile';
export const GET_COUNTRIES = BASE_URL + 'utils/getCountries';
export const GET_STATES_AND_COUNTY_BY_COUNTRY = BASE_URL + 'utils/getStatesAndCountyByCountry';
export const GET_MARSTAT = BASE_URL + 'employee/getmaritalstatus';
export const GET_CHECK_CODE = BASE_URL + 'account/GetCheckCode';
export const CHECK_CODE_COMPLETE_ACCOUNT = BASE_URL + 'account/checkCodeCompleteAccount';
export const COMPLETE_ACCOUNT_AGENT = BASE_URL + 'account/profile/owner';
export const SUSCRIPTION_REGISTER = BASE_URL + 'company/suscription';
export const VERIFY_MERCHID = BASE_URL + 'account/merchant';
export const SET_SOL_CREDENTIAL = BASE_URL + 'account/merchant/request';
export const GET_CHECK_CODE_COMPLETE_PROFILE = BASE_URL + 'system/GetCheckCodeCompleteProfile';
export const SET_NEW_PASSWORD = BASE_URL + 'system/SetNewPassword';
export const SEND_NOTIFICATION = BASE_URL + 'system/SendNotification';
export const GET_ALL_COMAPANIESMINI = BASE_URL + 'company/allCompaniesWEB';
export const GET_ALL_TERMSSP = BASE_URL + 'company/getAllTermsSpecific';
export const CHECK_VALID_TOKEN = BASE_URL + 'system/CheckValidToken';
export const SUBSCRIBE = BASE_URL + 'system/Subscribe';
export const SEND_PRESS_MEDIA = BASE_URL + 'website/pressmediarequest';

export const ADD_DATA_SUPP_CASE = BASE_URL_API + 'support/addData';
export const ADD_DATA_SUPP_DEV = BASE_URL_API + 'support/addData';
export const ADD_DATA_SUPP_BAJA = BASE_URL_API + 'support/addData';

export const REQU_PASSER_CARD = BASE_URL_API + 'passerCard/request';
export const SEND_LINK = BASE_URL_API + 'web/request';
export const CREATE_CLIENT = BASE_URL_API + 'client/';
export const LOGINN = BASE_URL_API + 'oauth/signinreports';
export const LOGOutt = BASE_URL_API + 'oauth/signout';

export const GET_ENTITY_TYPES_CATALOG = BASE_CATALOG_API + 'api/businessEntityTypes'
export const GET_COUNTRIES_CATALOG = BASE_CATALOG_API + 'api/countries'
export const GET_BUSINESS_ACTIVITY_CATALOG = BASE_CATALOG_API + 'api/businessActivities'
export const GET_CURRENCY_CATALOG = BASE_CATALOG_API + 'api/currencies'
export const GET_ID_TYPES_CATALOG = BASE_CATALOG_API + 'api/identificationTypes'
export const GET_OCCUPATIONS_CATALOG = BASE_CATALOG_API + 'api/occupations'
export const GET_FOUNDS_CATALOG = BASE_CATALOG_API + 'api/fundSources'
export const GET_FINANCIAL_ENTITIES = BASE_CATALOG_API + 'api/financialEntities';

export const POST_ACCOUNT_VERIFYPHONE = BASE_IDP_API + '/api/accounts/verifyPhone';
export const POST_ACCOUNT_VERIFYEMAIL = BASE_IDP_API + '/api/accounts/verifyEmail';
export const POST_ACCOUNT_BEGINAUTH = BASE_IDP_API + '/api/accounts/beginAuth';
export const POST_ACCOUNT_ENDAUTH = BASE_IDP_API + '/api/accounts/endAuth';
export const POST_ACCOUNT_TOKEN = BASE_IDP_API + '/token';
export const POST_ACCOUNT_USERINFO = BASE_IDP_API + '/me';
export const POST_ACCOUNT = BASE_IDP_API + '/api/accounts';
export const PATCH_ACCOUNT_RECOVERY_PASSWORD = BASE_IDP_API + '/api/providers/Passer.Authorization/recovery/password';
export const GET_ACCOUNT_ROLE = BASE_IDP_API + '/api/providers/Passer.Management/roles';
export const PATCH_ACCOUNT_IDPSUB = BASE_URL + 'accountOnBoarding/idpSub';

export const termsAndPolicy = '/passer-legal';
export const getAllCompanies = '/allCompaniesWeb';
export const getTermsSpecificCopny = '/getAllTermsSpecific';
import { Component, OnInit, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Validator } from '../../../data/validator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  translate: TranslateService;
  lang = 'es';
  formGroup: FormGroup;
  alertInput = false;
  validationActive: boolean;
  message: string;
  error: boolean;

  data = {
    password: '',
    confirm: '',
    state: '',
  };

  constructor(private router: Router, translate: TranslateService, private formBuilder: FormBuilder) {
    this.translate = translate;
    this.lang = this.translate.currentLang;

    this.formGroup = this.formBuilder.group({
      password: ['', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(16),
          Validator.patternValidator(/\d/, { hasNumber: true }),
          Validator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          Validator.patternValidator(/[a-z]/, { hasSmallCase: true })
      ]],
      confirmPassword: ['', Validators.required],
      check: ['']
    }, { validator: Validator.MatchPassword });
  }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  get f() { return this.formGroup.controls; }

  submit() {
    this.validationActive = true;
    this.alertInput = true;
    if (!this.validateForm() && this.alertInput && !this.f.confirmPassword.errors && !this.f.password.errors) {
      if (this.data.password === this.data.confirm) {
        this.validationActive = false;
        this.router.navigate(['/welcome']);
      } else {
        this.showSnackMessage('alerts.conf_pass');
        this.data.confirm = '';
      }
    }
  }

  validateForm() {
    return (this.validateField(this.data.password) || this.validateField(this.data.confirm));
  }

  validateField(value: string): boolean {
    return (this.validationActive && value === '');
  }

  showSnackMessage(message) {
    this.message = message;
    const snackbar = document.getElementById('snackbar');
    snackbar.className = 'show';
    setTimeout(function() { snackbar.className = snackbar.className.replace('show', ''); }, 3000);
  }

  changeLang() {
    this.translate.use(this.lang);
  }
}

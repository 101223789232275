import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import {
  BASE_IDP_API,
  SET_ACCOUNT_PROFILE,
  GET_CHECK_CODE,
  GET_CHECK_CODE_COMPLETE_PROFILE,
  CHECK_CODE_COMPLETE_ACCOUNT,
  COMPLETE_ACCOUNT_AGENT,
  SUSCRIPTION_REGISTER,
  VERIFY_MERCHID,
  SET_SOL_CREDENTIAL,
  POST_ACCOUNT_VERIFYPHONE,
  POST_ACCOUNT_VERIFYEMAIL,
  POST_ACCOUNT_BEGINAUTH,
  POST_ACCOUNT_ENDAUTH,
  POST_ACCOUNT_TOKEN,
  POST_ACCOUNT_USERINFO,
  POST_ACCOUNT,
  PATCH_ACCOUNT_RECOVERY_PASSWORD,
  GET_ACCOUNT_ROLE,
  PATCH_ACCOUNT_IDPSUB
} from "./constants";
import { ApiServiceResponse, Account } from "./api.service.response";
import {
  AccountInformation,
  IndPhone,
  MerchIDSave,
  SolCredential,
  Suscription,
} from "../models/account-information.model";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "../environments/environment";

@Injectable()
export class AccountService {
  constructor(private api: ApiService) {}

  setAccountProfile(data: any, contry: any, success, error) {
    // tslint:disable-next-line: prefer-const
    let phone = contry.code + data.phoneNumber;
    this.api
      .post<ApiServiceResponse>(SET_ACCOUNT_PROFILE, {
        phone: phone,
        idCommerce: this.api.token,
        checkCode: data.code,
        password: data.password,
        passwordConfirm: data.confirm,
      })
      .then(
        (response: ApiServiceResponse) => {
          if (response.success === true) {
            success(response.data);
          } else {
            error(response.message);
          }
        },
        (response: ApiServiceResponse) => {
          error(response.message);
        }
      );
  }

  getcheckcode(data: any, success, error, apiToken = null) {
    this.api
      .post<ApiServiceResponse>(GET_CHECK_CODE, {
        phone: data.phone,
        idCommerce: apiToken ? apiToken : this.api.token,
        resend: typeof data.resend === "undefined" ? false : data.resend,
      })
      .then(
        (response: ApiServiceResponse) => {
          if (response?.success === true) {
            success(response.data);
          } else {
            error(response?.message);
          }
        },
        (response: ApiServiceResponse) => {
          error(response?.message);
        }
      );
  }

  checkCodeCompleteAccount(verificationCode, success, error, apiToken = null) {
    this.api
      .post<ApiServiceResponse>(GET_CHECK_CODE_COMPLETE_PROFILE, {
        verificationCode: verificationCode,
        idCommerce: apiToken ? apiToken : this.api.token,
      })
      .then(
        (response: ApiServiceResponse) => {
          if (response?.success === true) {
            success(response.data);
          } else {
            error(response?.message);
          }
        },
        (response: ApiServiceResponse) => {
          error(response?.message);
        }
      );
  }

  checkCodeCompleteEnterpriseAccount(
    verificationCode,
    success,
    error,
    apiToken = null
  ) {
    this.api
      .post<ApiServiceResponse>(CHECK_CODE_COMPLETE_ACCOUNT, {
        verificationCode: verificationCode,
        idCommerce: apiToken ? apiToken : this.api.token,
      })
      .then(
        (response: ApiServiceResponse) => {
          if (response?.success === true) {
            success(response.data);
          } else {
            error(response?.message);
          }
        },
        (response: ApiServiceResponse) => {
          error(response?.message);
        }
      );
  }

  setToken(token: string): void {
    this.api.setToken(token);
  }

  completeAccountInformation(data: AccountInformation, success, error) {
    console.log(COMPLETE_ACCOUNT_AGENT);
    this.api.post<ApiServiceResponse>(COMPLETE_ACCOUNT_AGENT, data).then(
      (response: ApiServiceResponse) => {
        console.log(response);
        if (response?.success === true) {
          success(response.data);
        } else {
          error(response?.message);
        }
      },
      (response: ApiServiceResponse) => {
        console.error(response);
        error(response?.message);
      }
    );
  }

  suscriptionRegister(data: Suscription, success, error) {
    this.api.post<ApiServiceResponse>(SUSCRIPTION_REGISTER, data).then(
      (response: ApiServiceResponse) => {
        console.log(response);
        if (response?.success === true) {
          success(response.data);
        } else {
          error(response?.message);
        }
      },
      (response: ApiServiceResponse) => {
        console.error(response);
        error(response?.message);
      }
    );
  }

  verifyMerch(data: MerchIDSave, success, error) {
    this.api.put<ApiServiceResponse>(VERIFY_MERCHID, data).then(
      (response: ApiServiceResponse) => {
        console.log(response);
        if (response?.success === true) {
          success(response.data);
        } else {
          error(response?.message);
        }
      },
      (response: ApiServiceResponse) => {
        console.error(response);
        error(response?.message);
      }
    );
  }

  solCredMerch(data: SolCredential, success, error) {
    this.api.post<ApiServiceResponse>(SET_SOL_CREDENTIAL, data, true).then(
      (response: ApiServiceResponse) => {
        console.log(response);
        if (response?.success === true) {
          success(response.data);
        } else {
          error(response?.message);
        }
      },
      (response: ApiServiceResponse) => {
        console.error(response);
        error(response?.message);
      }
    );
  }

  idpAccountVerifyPhone(data: IndPhone, success, error) {
    this.api.post<ApiServiceResponse>(POST_ACCOUNT_VERIFYPHONE, data).then(
      (response: ApiServiceResponse) => {
        if (response?.success === true) {
          success(response.data);
        } else {
          success(null);
        }
      },
      (response: ApiServiceResponse) => {
        error(response?.message);
      }
    );
  }

  idpAccountBeginAuth(data: IndPhone, success, error) {
    this.api.postWithHeaders<ApiServiceResponse>(POST_ACCOUNT_BEGINAUTH, this.idpAuthMethodHeaders("OneWaySms"), {
        to: data.e164,
      })
      .then(
        (response: ApiServiceResponse) => {
          success(response);
        },
        (response: ApiServiceResponse) => {
          error(response?.message);
        }
      );
  }

  idpAccountBeginAuthEmail(data: string, success, error) {
    this.api
      .postWithHeaders<ApiServiceResponse>(POST_ACCOUNT_BEGINAUTH, this.idpAuthMethodHeaders("OneWayEmail"), {
        to: data,
      })
      .then(
        (response: ApiServiceResponse) => {
          success(response);
        },
        (response: ApiServiceResponse) => {
          error(response?.message);
        }
      );
  }

  idpAccountEndAuthEmail(data: string, code_challenge: string, otp: string, success, error) {
    this.api.postWithHeaders<ApiServiceResponse>(POST_ACCOUNT_ENDAUTH, this.idpAuthMethodHeaders("OneWayEmail"), {
        client_id: environment.idpClientId,
        client_secret: environment.idpClientSecret,
        to: data,
        otp,
        scope: "openid profile email",
        code_challenge: code_challenge,
        code_challenge_method: "S256",
        redirect_uri: environment.idpRedirectUri
      }).then(
        (response: ApiServiceResponse) => {
          success(response);
        },
        (response: ApiServiceResponse) => {
          error(response?.message);
        }
      );
  }

  idpAccountEndAuth(data: IndPhone, code_challenge: string, otp: string, success, error) {
    this.api.postWithHeaders<ApiServiceResponse>(POST_ACCOUNT_ENDAUTH, this.idpAuthMethodHeaders("OneWaySms"), {
        client_id: environment.idpClientId,
        client_secret: environment.idpClientSecret,
        to: data.e164,
        otp,
        scope: "openid profile email",
        code_challenge: code_challenge,
        code_challenge_method: "S256",
        redirect_uri: environment.idpRedirectUri
      }).then(
        (response: ApiServiceResponse) => {
          success(response);
        },
        (response: ApiServiceResponse) => {
          error(response?.message);
        }
      );
  }

  idpAuthToken(code: string, code_verifier: string, success, error) {
    let body = `grant_type=authorization_code&client_id=${environment.idpClientId}&client_secret=${environment.idpClientSecret}&code=${code}&code_verifier=${code_verifier}&redirect_uri=${environment.idpRedirectUri}`;
    this.api
      .postFormUrlEncoded<ApiServiceResponse>(POST_ACCOUNT_TOKEN, body)
      .then(
        (response: ApiServiceResponse) => {
          success(response);
        },
        (response: ApiServiceResponse) => {
          error(response?.message);
        }
      );
  }

  idpBasicAuthToken(success, error){
    const body = `grant_type=client_credentials&client_id=${environment.idpClientId}&client_secret=${environment.idpClientSecret}`;
    this.api.postFormUrlEncoded<ApiServiceResponse>(POST_ACCOUNT_TOKEN, body).then(
      (response: any) => {
        success(response.access_token);
      },
      (response: any) => {
        error(response);
      });
  }

  idpUserInfo(authToken: string, success, error) {
    this.api.getWithHeaders<Account>(POST_ACCOUNT_USERINFO, this.idpAuthHeaders(authToken)).then(
      (response: Account) => {
        success(response);
      },
      (response: Account) => {
        error(response);
      }
    );
  }

  idpAccountVerifyEmail(data: string, success, error) {
    this.api.post<any>(POST_ACCOUNT_VERIFYEMAIL, data).then(
      (response: any) => {
        success(response);
      },
      (response: any) => {
        error(response);
      }
    );
  }

  idpPostRoleAssignments(authToken: string, scope: string, data: any, success, error) {
    this.api.postWithHeaders<any>(`${BASE_IDP_API}${scope}`, this.idpAuthHeaders(authToken), data).then(
      (response: any) => {
        success(response);
      },
      (error: any) => {
        error(error);
      }
    );
  }

  idpGetRoleAssignment(authToken: string, scope: string, accountId: string, success, error) {
    this.api.getWithHeaders<any>(`${BASE_IDP_API}${scope}?$filter=assignedTo('${accountId}')`, this.idpAuthHeaders(authToken)).then(
      (response: any) => {
        success(response);
      },
      (error: any) => {
        error(error);
      }
    );
  }

  idpAccountUpsert(authToken: string, account: Account, success, error) {
    this.api.postWithHeaders<any>(POST_ACCOUNT, this.idpAuthHeaders(authToken), account).then(
      (response: any) => {
        success(response);
      },
      (response: any) => {
        error(response);
      }
    );
  }
  
  adminAccountIdpSubUpsert(authToken: string, idCommerce: string, account: Account, decodeToken : any, sex :string , success, error){
    if (!authToken) {
      this.idpBasicAuthToken(
        (authToken) => {
          this.adminApiPatchIdpSub(authToken, idCommerce, account, decodeToken, sex,  success, error);
        },
        (error) => {
          error(error);
        }
      )
    } else {
      this.adminApiPatchIdpSub(authToken, idCommerce, account, decodeToken, sex,  success, error);
    }
  }

  adminApiPatchIdpSub(authToken: string, idCommerce: string, account: Account, decodeToken: any, sex: string, success, error){
    this.api.postWithHeaders<any>(PATCH_ACCOUNT_IDPSUB, this.idpAuthHeaders(authToken), { 
      account: idCommerce, 
      idpSub: account.accountId,
      emluser: account.email,
      namuser: account.name,
      phouser: account.phone.nationalNumber,
      phone: account.phone,
      userEmail: decodeToken.userEmail,
      sex,
      systemRole: decodeToken.systemRole,
      managementGroup: decodeToken.managementGroup,
      subscription: decodeToken.subscription,
     })
    .then((response)=>{
      success(response);
    },
    (adminError)=>{
      error(adminError);
    });
  }

  idpAuthHeaders(authToken: string){
    let headers = new HttpHeaders();
    if(authToken)
      headers = headers.append("Authorization", authToken);
    headers = headers.append("Content-Type", "application/json");
    return headers;
  }

  idpAuthMethodHeaders(method: string){
    let headers = new HttpHeaders();
    headers = headers.append("Auth-Method", method);
    headers = headers.append("Content-Type", "application/json");
    headers = headers.append("Accept-Language", "es");
    return headers;
  }

  idpPatchRecoveryPassword(token: string, newPassword: string, confirmPassword: string, authToken: string, success, error) {
    this.api.patchWithHeaders<any>(PATCH_ACCOUNT_RECOVERY_PASSWORD, this.idpAuthHeaders(authToken), {
      token,
      newPassword,
      confirmPassword
    }).then(
      (response: any) => {
        success(response);
      },
      (error: any) => {
        error(error);
      }
    );
  }

  idpGetRoleDefinition(roleDefinitionId: string, authToken: string, success: any, error: any) {
    this.api.getWithHeaders<any>(`${GET_ACCOUNT_ROLE}/${roleDefinitionId}`, this.idpAuthHeaders(authToken)).then(
      (response: any) => {
        success(response);
      },
      (error: any) => {
        error(error);
      }
    );
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alert-selector',
  templateUrl: './alert-selector.component.html',
  styleUrls: ['./alert-selector.component.scss'],
})
export class AlertSelectorComponent implements OnInit {


  @Input('iconColor') iconColor: string;
  @Input('icon') icon: string;
  @Input('text') text: string;
  @Input('placeholder') placeholder: string;
  @Input('hasBorder') hasBorder: boolean;
  @Input('isDisabled') isDisabled: boolean;
  
  
  textToPresent = "";
  noText = true;

  @Output() OnClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.textToPresent = (this.text && this.text !== '') ? this.text : this.placeholder;
    this.noText = !this.text || this.text === '';
  }


  openSelect(){
    this.OnClick.emit(true);
  }


  ngDoCheck(){ 
    this.textToPresent = (this.text && this.text !== '') ? this.text : this.placeholder;
    this.noText = !this.text || this.text === '';
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss']
})
export class CompletedComponent implements OnInit {

  translate: TranslateService;
  lang = 'es';
  visibleCodes: Boolean;
  message: String;

  ngOnInit(): void {
  }
  constructor(private router: Router, translate: TranslateService) {
    this.translate = translate;
    this.lang = this.translate.currentLang;
  }

  submit() {
    this.router.navigate(['/welcome']);
  }

  changeLang() {
    this.translate.use(this.lang);
  }
}

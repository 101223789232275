import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-boxed-container',
  templateUrl: './boxed-container.component.html',
  styleUrls: ['./boxed-container.component.scss']
})
export class BoxedContainerComponent implements OnInit {


  @Input() public title: string;
  @Input() public color1: string;
  @Input() public color2: string;
  @Input() public color3: string;
  @Input() public color4: string;
  @Input() public titleDot: boolean;

  constructor() { }

  ngOnInit() {
  }

}

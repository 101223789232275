import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApiServiceResponse } from './api.service.response';
import { GET_ENTITY_TYPES_CATALOG, GET_COUNTRIES_CATALOG, GET_BUSINESS_ACTIVITY_CATALOG, GET_CURRENCY_CATALOG, GET_ID_TYPES_CATALOG, GET_OCCUPATIONS_CATALOG, GET_FOUNDS_CATALOG, GET_FINANCIAL_ENTITIES } from './constants';

@Injectable()
export class CatalogsService {

    constructor(private api: ApiService) { }

    fetchCountries(success, error) {
        this.api.getWithSimpleHeadears<ApiServiceResponse>(GET_COUNTRIES_CATALOG, '').then(
            (response: any) => {
                success(response.data);
            },
            (response: ApiServiceResponse) => {
                error(response?.message);
            }
        );
    }

    fetchEntityTypes(success, error) {
        this.api.getWithSimpleHeadears<ApiServiceResponse>(GET_ENTITY_TYPES_CATALOG, '').then(
            (response: any) => {
                success(response.data);
            },
            (response: ApiServiceResponse) => {
                error(response?.message);
            }
        );
    }

    fetchBusinessActivities(success, error) {
        this.api.getWithSimpleHeadears<ApiServiceResponse>(GET_BUSINESS_ACTIVITY_CATALOG, '').then(
            (response: any) => {
                success(response.data);
            },
            (response: ApiServiceResponse) => {
                error(response?.message);
            }
        );
    }

    fetchCurrencies(success, error) {
        this.api.getWithSimpleHeadears<ApiServiceResponse>(GET_CURRENCY_CATALOG, '').then(
            (response: any) => {
                success(response.data);
            },
            (response: ApiServiceResponse) => {
                error(response?.message);
            }
        );
    }

    fetchFounds(success, error) {
        this.api.getWithSimpleHeadears<ApiServiceResponse>(GET_FOUNDS_CATALOG, '').then(
            (response: any) => {
                success(response.data);
            },
            (response: ApiServiceResponse) => {
                error(response?.message);
            }
        );
    }

    fetchIdentificationTypes(success, error) {
        console.log(GET_ENTITY_TYPES_CATALOG)
        this.api.getWithSimpleHeadears<ApiServiceResponse>(GET_ID_TYPES_CATALOG, '').then(
            (response: any) => {
                success(response.data);
            },
            (response: ApiServiceResponse) => {
                error(response?.message);
            }
        );
    }

    fetchOccupations(success, error) {
        console.log(GET_ENTITY_TYPES_CATALOG)
        this.api.getWithSimpleHeadears<ApiServiceResponse>(GET_OCCUPATIONS_CATALOG, '').then(
            (response: any) => {
                success(response.data);
            },
            (response: ApiServiceResponse) => {
                error(response?.message);
            }
        );
    }

    fetchFinaltialEnt(success, error) {
      this.api.getWithSimpleHeadears<ApiServiceResponse>(GET_FINANCIAL_ENTITIES, '').then(
          (response: any) => {
              success(response.data);
          },
          (response: ApiServiceResponse) => {
              error(response?.message);
          }
      );
    }
}

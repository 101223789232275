import { ApiService } from 'src/api/api.service';
import { ApiServiceResponse } from 'src/api/api.service.response';
import { CHECK_VALID_TOKEN } from 'src/api/constants';

export class TokenValidator {

    static validateToken(api: ApiService, tkn, success, error) {
      api
      .post<ApiServiceResponse>(CHECK_VALID_TOKEN, { tkn: tkn })
      .then(
        (response: ApiServiceResponse) => {
          if (response.success) {
            success(response);
          } else {
            error(response);
          }
        },
        (response: ApiServiceResponse) => {
          console.log('Rejected');
          console.log(response);
          error(response);
        }
      );
    }
}

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import { AccessCredentialsComponent } from 'src/pages/sign-in/access-credentials/access-credentials.component';
import { AccountProtectionComponent } from 'src/pages/sign-in/account-protection/account-protection.component';
import { ChangePasswordComponent } from 'src/pages/sign-in/change-password/change-password.component';
import { ResetPasswordComponent } from 'src/pages/sign-in/reset-password/reset-password.component';
import { VerifyAccountComponent } from 'src/pages/sign-in/verify-account/verify-account.component';
import { CompletedComponent } from 'src/pages/sign-in/completed/completed.component';
import { WelcomeComponent } from 'src/pages/sign-in/welcome/welcome.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'descubrir',
    loadChildren: () => import('../pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('../pages/calendar/calendar.module').then(m => m.CalendarModule)
  },
  {
    path: 'passer-card',
    loadChildren: () => import('../pages/passer-card/passer-card.module').then(m => m.PasserCardModule)
  },
  {
    path: 'enterprises',
    loadChildren: () => import('../pages/enterprise/enterprise.module').then(m => m.EnterpriseModule)
  },
  {
    path: 'demostracion',
    loadChildren: () => import('../pages/enterprise/enterprise.module').then(m => m.EnterpriseModule)
  },
  {
    path: 'legal',
    loadChildren: () => import('../pages/legal/legal.module').then(m => m.LegalModule)
  },
  {
    path: 'rules',
    loadChildren: () => import('../pages/rules/rules.module').then(m => m.RulesModule)
  },
  {
    path: '404',
    loadChildren: () => import('../pages/not-found/not-found.module').then(mod => mod.NotFoundModule)
  },
  /*{
    path: 'bitpass',
    loadChildren: () => import('../pages/bitpass/bitpass.module').then(mod => mod.BitpassModule)
  },*/
  {
    path: 'brand-guidelines',
    loadChildren: () => import('../pages/brand/brand.module').then(mod => mod.BrandModule)
  },
  {
    path: 'printing-press',
    loadChildren: () => import('../pages/infomation/infomation.module').then(mod => mod.InfomationModule)
  },
  {
    path : 'companies',
    loadChildren: () => import('../pages/companies/companies.module').then(mod => mod.CompaniesModule)
  },
  {
    path: 'recommend',
    loadChildren: () => import('../pages/recommend/recommend.module').then(mod => mod.RecommendModule)
  },
  {
    path: 'support',
    loadChildren: () => import('../pages/support/support.module').then(mod => mod.SupportModule)
  },
  {
    path: 'completeAccount/:token',
    loadChildren: () => import('../pages/complete-account/complete-account.module').then(mod => mod.CompleteAccountModule)
  },
  // this has to be removed is a few updates**
  /*{
    path: 'completeAccount/:accesstkn',
    component: AccessCredentialsComponent
  },*/
  {
    path: 'account-protection',
    component: AccountProtectionComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'verify',
    component: VerifyAccountComponent
  },
  {
    path: 'completed',
    component: CompletedComponent
  },
  {
    path: 'welcome',
    component: WelcomeComponent
  },
  {
    path: '**',
    loadChildren: () => import('../pages/not-found/not-found.module').then(mod => mod.NotFoundModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    }),
    TranslateModule

  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

export class General {

  static open(url: string) { window.open(url); }

  static getHref() { return window.location.href; }

  static scrollTo(num1, num2) { window.scrollTo(num1, num2); }

  static scroll(num1, num2) { window.scroll(num1, num2); }

  static dispatchEvent(event) { window.dispatchEvent(event); }

  static deleteStressM(stg: string): string {
    return stg
      .normalize('NFD')
      .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,'$1')
      .normalize();
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {


  @Input() public title: string;
  @Input() public text: string;
  @Input() public buttonTxt: string;
  @Input() public available: boolean;
  @Input() public link: boolean;

  constructor(private router: Router) { }

  ngOnInit() {
  }


  goTo(){
    this.router.navigate([this.link]);
  }

}

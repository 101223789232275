import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavbarComponent } from 'src/components/navbar/navbar.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from 'src/components/footer/footer.component';
import { SignUpComponent } from 'src/pages/sign-up/sign-up.component';
import { CountdownModule } from 'ngx-countdown';
import { AccountService } from 'src/api/account.service';
import { ApiService } from 'src/api/api.service';
import { EmailService } from 'src/api/email.service';
import { CatalogsService } from 'src/api/catalogs.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule } from '@ng-maps/google';
import { NgMapsCoreModule } from '@ng-maps/core';
// import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

import { AccessCredentialsComponent } from 'src/pages/sign-in/access-credentials/access-credentials.component';
import { AccountProtectionComponent } from 'src/pages/sign-in/account-protection/account-protection.component';
import { ChangePasswordComponent } from 'src/pages/sign-in/change-password/change-password.component';
import { ResetPasswordComponent } from 'src/pages/sign-in/reset-password/reset-password.component';
import { VerifyAccountComponent } from 'src/pages/sign-in/verify-account/verify-account.component';
import { CompletedComponent } from 'src/pages/sign-in/completed/completed.component';
import { WelcomeComponent } from 'src/pages/sign-in/welcome/welcome.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SEOService } from 'src/api/metatags.service';
import { ListRadiosComponent } from '../components/list-radios/list-radios.component';

import { AppMaterialModule } from './app.material.module';
import { SetDateStringService } from 'src/services/setDateString/setDateString';
import { CreditCardPipe } from 'src/pipes/format-creditcard';
import { SharedModule } from './shared.module';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent, NavbarComponent, FooterComponent, SignUpComponent,
    /*This should be deleted in future developments*/
    AccessCredentialsComponent, AccountProtectionComponent, ChangePasswordComponent, ResetPasswordComponent,
    VerifyAccountComponent, CompletedComponent, WelcomeComponent, ListRadiosComponent
  ],
  imports: [
    CountdownModule,
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'passer-web-site' }),
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppMaterialModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // NgMapsGoogleModule,
    // NgMapsCoreModule,
  ],
  providers: [
    ApiService, EmailService, AccountService, SEOService, CatalogsService,
    SetDateStringService, CreditCardPipe,
    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useValue: {
        apiKey: environment.API_KEY_MAPS,
        libraries: ['places']
      }
    },/* ,
    {
    provide: RECAPTCHA_SETTINGS,
    useValue: { siteKey: '6LcGNL8UAAAAACYNxpwMIADUB-UA9ifWIQmcYRq5' } as RecaptchaSettings,
  } */],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }

import { Component, OnInit, ViewChild } from '@angular/core';
import { CountdownComponent } from 'ngx-countdown';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/api/account.service';
import { ApiService } from 'src/api/api.service';
import { TranslateService } from '@ngx-translate/core';
import { Validator } from '../../../data/validator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TokenValidator } from '../../../data/tokenValidator';
import { General } from 'src/data/general';

const LENGTH_LONG = 5000;
const LENGTH_SHORT = 2000;

@Component({
  selector: 'app-access-credentials',
  templateUrl: './access-credentials.component.html',
  styleUrls: ['./access-credentials.component.scss']
})
export class AccessCredentialsComponent implements OnInit {

  general = General;
  formGroup: FormGroup;
  alertInput = false;
  translate: TranslateService;
  lang = 'es';
  @ViewChild(CountdownComponent) counter: CountdownComponent;
  countries = [
    { code: '+506', name: 'Costa Rica', img: 'costa-rica.png' },
    { code: '+507', name: 'Panamá', img: 'panama.png' },
    { code: '+505', name: 'Nicaragua', img: 'nicaragua.png' },
    { code: '+52', name: 'México', img: 'mexico.png' }
  ];
  validationActive: boolean;
  visibleCodes: boolean;
  message: string;
  error: boolean;
  contry: any;

  code = {
    value: '',
    runing: false,
    wasSend: false,
    error: false
  };

  data = {
    id: '',
    password: '',
    confirm: '',
    phoneNumber: '',
    code: '',
    resend: false
  };

  load = false;

  config = { leftTime: 120, format: 'mm:ss' };

  constructor(private router: Router,
    private route: ActivatedRoute,
    private api: AccountService,
    private apii: ApiService,
    translate: TranslateService,
    private formBuilder: FormBuilder) {
    this.translate = translate;
    this.lang = this.translate.currentLang;
    this.formGroup = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16),
        Validator.patternValidator(/\d/, { hasNumber: true }),
        Validator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        Validator.patternValidator(/[a-z]/, { hasSmallCase: true })
      ]],
      confirmPassword: ['', Validators.required],
      phone: [''],
      id: [''],
      digits: ['']
    }, { validator: Validator.MatchPassword });
  }

  ngOnInit(): void {
    const tkn = this.general.getHref().split('completeAccount/');
    if (tkn && tkn.length === 2) {
      TokenValidator.validateToken(this.apii, tkn[1], (success) => {
        this.load = true;
      },
      (error) => {
        this.load = false;
        this.router.navigate(['/**']);
      });
      this.api.setToken(tkn[1]);
    } else { this.router.navigate(['/**']); }
    // this.route.params.subscribe(params => this.api.setToken(params['accesstkn']));
    this.contry = this.countries[0];
    this.visibleCodes = false;
  }

  get f() { return this.formGroup.controls; }

  selectCode(contry: any) {
    this.contry = contry;
    this.visibleCodes = false;
    this.code.runing = false;
  }

  showCodes() {
    this.visibleCodes = true;
  }

  submit() {
    this.validationActive = true;
    this.alertInput = true;
    if (!this.f.password.errors && (this.validateForm() && this.alertInput)) {
      this.validationActive = false;
      if (!this.f.confirmPassword.errors && (this.data.password === this.data.confirm)) {
        this.setProfileData();
      } else {
        this.showM('alerts.conf_pass');
      }
    } else {
      if (this.f.password.errors && (this.f.password.errors.maxlength || this.f.password.errors.minLength)) {
        this.showM('alerts.minMax_pass');
      } else
        if (this.f.password.errors && (this.f.password.errors.hasCapitalCase || this.f.password.errors.hasSmallCase ||
          this.f.password.errors.hasNumber)) {
          this.showM('alerts.val_pass');
        }
    }
  }

  setProfileData() {
    this.api.setAccountProfile(this.data, this.contry,
      (success) => {
        window.location.assign(success.url);
      },
      (error) => {
        this.showSnackMessage(LENGTH_LONG, error);
      });
  }

  onFinished(event) {
    if (event.action === 'done') {
      this.code.runing = false;
    }
  }

  sendCode() {
    if (this.data.phoneNumber.length >= 8) {
      if (!this.code.runing) {
        this.data.resend = this.code.wasSend;
        this.api.getcheckcode(this.data, this.contry,
          (success) => {
            this.code.runing = true;
            this.code.wasSend = true;
          },
          (error) => {
            this.showSnackMessage(LENGTH_LONG, error);
            this.code.runing = false;
            this.code.wasSend = false;
          });
        if (this.counter !== undefined) {
          this.counter.restart();
        }
      } else {
        this.showSnackMessage(LENGTH_SHORT, 'alerts.noPhone');
        this.code.runing = false;
        this.code.wasSend = false;
      }
    }
  }

  validateForm() {
    return (this.validateField(this.data.id) || this.validateField(this.data.password)
      || this.validateField(this.data.confirm) || this.validateField(this.data.code)) ||
      this.validatePhoneNumber(this.data.phoneNumber);
  }

  validateField(value: string): boolean {
    return (this.validationActive && value === '');
  }

  validatePhoneNumber(value: string) {
    return (this.validationActive && (value === '' || value.length < 8));
  }

  numberOnly(event) {
    /* if (this.data.phoneNumber !== '') {
      this.data.phoneNumber = this.data.phoneNumber.replace(/[^0-9.]/g, '');
    } */
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) { return false; }
    return true;
  }

  showSnackMessage(duration, message) {
    this.message = message;
    const snackbar = document.getElementById('snackbar');
    snackbar.className = 'show';
    setTimeout(function () { snackbar.className = snackbar.className.replace('show', ''); }, duration);
  }

  showM(msn: any) {
    this.showSnackMessage(LENGTH_LONG, msn);
    this.data.confirm = '';
  }

  changeLang() {
    this.translate.use(this.lang);
  }
}

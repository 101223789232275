import { Pipe, PipeTransform } from '@angular/core';
import { setImage } from 'src/data/methods-general';

@Pipe({
  name: 'CreditCardPipe',
})
export class CreditCardPipe implements PipeTransform {

  transform(valString: string): string {
    let newval = '';
    valString = valString.replace(/\s/g, '');
    const re = new RegExp('^3[47]');
    if(valString.match(re) != null){
      if(valString.length > 4 && valString.length <= 10){
        newval = [valString.slice(0, 4), ' ', valString.slice(4)].join('');
        return newval;
      }
      if(valString.length > 10){
        newval = [valString.slice(0, 4), ' ', valString.slice(4,10), ' ', valString.slice(10,valString.length)].join('');
        return newval;
      }
      return valString;
    }else{
      for(let i=0; i < valString.length; i++) {
        if(i%4 === 0 && i > 0) newval = newval.concat(' ');
        newval = newval.concat(valString[i]);
      }
    }
    return newval;
  }

  getCardType(number): string {
    number = number.replace(/\s/g, '');
    let re = new RegExp('^4');
    if (number.match(re) != null){
      return setImage('visa');
    }

    re = new RegExp('^3[47]');
    if (number.match(re) != null){
      return setImage('american_express');
    }

    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)){
      return setImage('master');
    }

    re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
    if (number.match(re) != null){
      return setImage('Discover');
    }

    re = new RegExp('^36');
    if (number.match(re) != null){
      return setImage('Diners');
    }

    re = new RegExp('^35(2[89]|[3-8][0-9])');
    if (number.match(re) != null){
      return setImage('JCB');
    }

    return setImage('');
  }
}


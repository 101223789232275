import {AbstractControl, FormGroup, ValidationErrors , ValidatorFn} from '@angular/forms';

// custom validator to check that two fields match
export class Validator {

    static MatchPassword(AC: AbstractControl) {
        // tslint:disable-next-line: prefer-const
        let password = AC.get('password').value; // to get value in input tag
        // tslint:disable-next-line: prefer-const
        let confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
        if (confirmPassword === '') {
         AC.get('confirmPassword').setErrors( {required : true} );
         // tslint:disable-next-line: no-unused-expression
         AC.get('confirmPassword').valid;
         return;
        } else if (password !== confirmPassword) {
             console.log('false');
         AC.get('confirmPassword').setErrors( {MatchPassword: true} );
         } else {
             console.log('true');
         AC.get('confirmPassword').setErrors(null);
         AC.get('confirmPassword').markAsPristine();
         return;
      }
    }

    static confirmedValidator(controlName: string, matchingControlName: string) {
      return (formGroup: FormGroup): void => {
          const control = formGroup.controls[controlName];
          const matchingControl = formGroup.controls[matchingControlName];
          if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
              return;
          }
          if (control.value !== matchingControl.value) {
              matchingControl.setErrors({ confirmedValidator: true });
          } else {
              matchingControl.setErrors(null);
          }
      };
    }

   static emailValidator(AC: AbstractControl) {
        const email = AC.get('email').value;

        if (email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            return null;
        } else {
            return { 'invalidEmailAddress': true };
        }
    }

    static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
        if (!control.value) {
            // if control is empty return no error
            return null;
        }

          // test the value of the control against the regexp supplied
        const valid = regex.test(control.value);

          // if true, return no error (no error), else return error passed in the second parameter
        return valid ? null : error;
        };
    }
}

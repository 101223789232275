import { Component, OnInit, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import { CountdownComponent } from 'ngx-countdown';
// tslint:disable-next-line: import-spacing
import { TranslateService }   from '@ngx-translate/core';

@Component({
  selector: 'app-account-protection',
  templateUrl: './account-protection.component.html',
  styleUrls: ['./account-protection.component.scss']
})
export class AccountProtectionComponent implements OnInit {

  translate: TranslateService;
  lang = 'es';
  @ViewChild(CountdownComponent) counter: CountdownComponent;
  countries = [
    { code: '+506', name: 'Costa Rica', img: 'costa-rica.png' },
    { code: '+507', name: 'Panamá', img: 'panama.png' },
    { code: '+505', name: 'Nicaragua', img: 'nicaragua.png' },
    { code: '+52', name: 'México', img: 'mexico.png' }
  ];
  validationActive: boolean;
  visibleCodes: boolean;
  message: string;
  error: boolean;
  country: any;

  data = {
    phoneNumber: '',
    code: '',
    state: ''
  };
  code = { value: '', runing: false, error: false };

  config = { leftTime: 120, format: 'mm:ss' };

  constructor(private router: Router, translate: TranslateService) {
    this.translate = translate;
    this.lang = this.translate.currentLang;
  }

  ngOnInit(): void {
    this.country = this.countries[0];
    this.visibleCodes = false;
  }

  submit() {
    this.validationActive = true;
    if (!this.validateForm()) {
      this.validationActive = false;
        this.router.navigate(['/welcome']);
    }
  }

  selectCode(country: any) {
    this.country = country;
    this.visibleCodes = false;
    this.code.runing = false;
  }

  showCodes() {
    this.visibleCodes = true;
  }

  onFinished(event) {
    if (event.action === 'done') {
      this.code.runing = false;
    }
  }

  resendCode() {
    if (this.data.phoneNumber.length >= 8) {
      if (!this.code.runing) {
        this.code.runing = true;
        this.counter.restart();
      }
    } else {
      this.showSnackMessage('alerts.noPhone');
    }
  }

  validateForm() {
    return (this.validateField(this.data.code)) ||
      this.validatePhoneNumber(this.data.phoneNumber);
  }

  validateField(value: string): boolean {
    return (this.validationActive && value === '');
  }

  validatePhoneNumber(value: string) {
    return (this.validationActive && (value === '' || value.length < 8));
  }

  numberOnly(event) {
    return ( event.ctrlKey || event.altKey
      || (47 < event.keyCode && event.keyCode < 58 && event.shiftKey === false)
      || (95 < event.keyCode && event.keyCode < 106)
      || (event.keyCode === 8) || (event.keyCode === 9)
      || (event.keyCode > 34 && event.keyCode < 40)
      || (event.keyCode === 46) );
  }

  showSnackMessage(message) {
    this.message = message;
    const snackbar = document.getElementById('snackbar');
    snackbar.className = 'show';
    setTimeout(function() { snackbar.className = snackbar.className.replace('show', ''); }, 3000);
  }

  changeLang() {
    this.translate.use(this.lang);
  }
}

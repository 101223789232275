import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-presenter',
  templateUrl: './presenter.component.html',
  styleUrls: ['./presenter.component.scss']
})
export class PresenterComponent implements OnInit {


  @Input() public title: string;
  @Input() public text: string;
  @Input() public img: string;

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { CountdownComponent } from 'ngx-countdown';
import { ApiService } from 'src/api/api.service';
import { ApiServiceResponse } from 'src/api/api.service.response';
import {
  SET_EMPLOYEE_ACCOUNT_PROFILE,
  GET_CHECK_CODE_COMPLETE_PROFILE,
  GET_STATES_AND_COUNTY_BY_COUNTRY,
  GET_MARSTAT
} from 'src/api/constants';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Validator } from '../../../data/validator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TokenValidator } from '../../../data/tokenValidator';
import { General } from 'src/data/general';
import { environment } from 'src/environments/environment';
import { COUNTRIES } from 'src/data/countries';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  general = General;
  translate: TranslateService;
  lang = 'es';
  formGroup: FormGroup;
  alertInput = false;
  @ViewChild(CountdownComponent) counter: CountdownComponent;
  idCommerce = '';
  civils: any = [];
  countries = COUNTRIES.slice(0);
  states = [];
  countiesBase = [];
  counties = [];
  validationActive: boolean;
  visibleCodes: boolean;
  message: string;
  error: boolean;
  isCompleteAccountSuccess = false;
  enterprisesUrl = '';

  checkTerms: boolean = false;
  test = '';
  copnyName = '';
  permis = '';
  load = false;

  data = {
    id: '',
    password: '',
    confirm: '',
    phoneNumber: '',
    code: ''
  };

  profile = {
    national: null,
    civil: null,
    name: '',
    lastname1: '',
    lastname2: '',
    type: '',
    id: '',
    image: null,
    nickname: '',
    country: null,
    countrySelect: null,
    state: null,
    city: null,
    description: ''
  };

  code = { value: '', runing: false, error: false, wasSend: false };
  config = { leftTime: 5, format: 'mm:ss' };

  constructor(private router: Router, private api: ApiService, private activatedRoute: ActivatedRoute,
              translate: TranslateService, private formBuilder: FormBuilder) {
    this.translate = translate;
    this.lang = this.translate.currentLang;
    this.formGroup = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16),
        Validator.patternValidator(/\d/, { hasNumber: true }),
        Validator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        Validator.patternValidator(/[a-z]/, { hasSmallCase: true })
      ]],
      confirmPassword: ['', Validators.required],
      phone: [''],
      nickname: [''],
      digits: ['']
    }, { validator: Validator.MatchPassword });
    this.getMaritalStat();
  }

  get f() { return this.formGroup.controls; }

  ngOnInit() {
    const url = this.general.getHref();
    this.test = (environment.production) ? '' : 'test.';
    this.visibleCodes = false;

    const tkn = this.general.getHref().split('welcome?tkn=');
    if (tkn && tkn.length === 2) {
      TokenValidator.validateToken(this.api, tkn[1], (success) => {
        this.copnyName = success.data.company;
        this.permis = success.data.username;
        this.load = true;
      },
      (error) => {
        this.load = false;
        this.router.navigate(['/**']);
      });
      this.idCommerce = tkn[1];
    } else { this.router.navigate(['/**']); }
  }

  selectCode(country: any) {
    this.profile.country = country;
    this.visibleCodes = false;
    this.code.runing = false;
  }

  showCodes(visible) { this.visibleCodes = visible; }

  submit() {
    this.validationActive = true;
    this.alertInput = true;
    if (!this.validateForm() && this.checkTerms && this.alertInput && !this.f.confirmPassword.errors && !this.f.password.errors) {
      this.validationActive = false;
      if (this.profile.image === undefined) {
        this.showSnackMessage('alerts.photo_req');
      } else {
        this.api
          .post<ApiServiceResponse>(SET_EMPLOYEE_ACCOUNT_PROFILE, {
            tkn: this.idCommerce,
            username: this.profile.name,
            lastnames1: this.profile.lastname1,
            lastnames2: this.profile.lastname2,
            avatar: this.profile.image,
            idtype: this.profile.type,
            country: this.profile.countrySelect,
            state: this.profile.state,
            county: this.profile.city,
            id: this.profile.id,
            nickname: this.profile.nickname,
            password: this.data.password,
            phone: this.profile.country.phonePrefix + this.data.phoneNumber,
            checkCode: this.data.code,
            indnationality: this.profile.national,
            indmaritalstatus: this.profile.civil,
            indaddress: this.profile.description
          })
          .then(
            (response: ApiServiceResponse) => {
              if (response.success === true) {
                this.showSnackMessage('actions.sent');
                this.isCompleteAccountSuccess = true;
                this.enterprisesUrl = response.data;
              } else {
                this.isCompleteAccountSuccess = false;
                this.showSnackMessage(response.message);
              }
            },
            (response: ApiServiceResponse) => {
              this.showSnackMessage('alerts.err_photo');
            }
          );
        // this.router.navigate(['/change-password']);
      }
    }
  }

  onFinished(event) {
    if (event.action === 'done') {
      this.code.runing = false;
    }
  }

  sendCode() {
    if (this.data.phoneNumber.length >= 8) {
      if (!this.code.runing) {
        this.api.post<ApiServiceResponse>(GET_CHECK_CODE_COMPLETE_PROFILE, {
          tkn: this.idCommerce,
          phone: this.profile.country.phonePrefix + this.data.phoneNumber,
          resend: this.code.wasSend
        }).then(
          (response: ApiServiceResponse) => {
            this.code.runing = true;
            this.code.wasSend = true;
            this.counter.restart();
          },
          (response: ApiServiceResponse) => {
            this.showSnackMessage('alerts.err_code2');
            this.code.wasSend = false;
          }
        );
      }
    } else {
      this.showSnackMessage('alerts.noPhone');
      this.code.wasSend = false;
    }
  }

  validateForm() {
    return (
      this.validatePhoneNumber(this.data.phoneNumber) ||
      this.validateField(this.data.password) ||
      this.validateField(this.data.confirm) ||
      this.validateField(this.data.code) ||
      this.validateField(this.profile.national) ||
      this.validateField(this.profile.civil) ||
      this.validateField(this.profile.name) ||
      this.validateField(this.profile.lastname1) ||
      this.validateField(this.profile.lastname2) ||
      this.validateField(this.profile.type) ||
      this.validateField(this.profile.id) ||
      this.validateField(this.profile.description) ||
      this.validateField(this.profile.nickname) ||
      this.validateField(this.profile.country.name) ||
      this.validateField(this.profile.countrySelect) ||
      this.validateField(this.profile.state) ||
      this.validateField(this.profile.city)
    );
  }

  validateField(value: string): boolean {
    return this.validationActive && !value;
  }

  validateFieldCheck(value: boolean): boolean {
    return this.validationActive && !value;
  }

  validatePhoneNumber(value: string) {
    return this.validationActive && (value === '' || value.length < 8);
  }

  numberOnly(event) {
    return ( event.ctrlKey || event.altKey
      || (47 < event.keyCode && event.keyCode < 58 && event.shiftKey === false)
      || (95 < event.keyCode && event.keyCode < 106)
      || (event.keyCode === 8) || (event.keyCode === 9)
      || (event.keyCode > 34 && event.keyCode < 40)
      || (event.keyCode === 46) );
  }

  showSnackMessage(message) {
    this.message = message;
    const snackbar = document.getElementById('snackbar');
    snackbar.className = 'show';
    setTimeout(function () {
      snackbar.className = snackbar.className.replace('show', '');
    }, 3000);
  }

  getProfileImage() {
    return this.profile.image ? this.profile.image : '../../assets/img/sing-up/profile.PNG';
  }

  uploadProfileImage() {
    document.getElementById('profileImage').click();
  }

  fileSelected(event: any) {
    const file: File = event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = e => {
      this.profile.image = myReader.result;
    };
    myReader.readAsDataURL(file);
  }

  getStatesAndCounties(country: any) {
    this.states = [];
    this.countiesBase = [];
    this.api
      .post<ApiServiceResponse>(GET_STATES_AND_COUNTY_BY_COUNTRY, {country})
      .then(
        (response: ApiServiceResponse) => {
          response.data.forEach(element => {
            if (this.states.findIndex(elm => elm.state === element['state']) === -1) {
              this.states.push({
                state: element['state'],
                name: element['indstatename']
              });
            }

            if (this.countiesBase.findIndex(elm => elm.county === element['county']) === -1) {
              this.countiesBase.push({
                state: element['state'],
                county: element['county'],
                name: element['indcountyname']
              });
            }
          });
          this.profile.state = null;
        },
        (response: ApiServiceResponse) => {
          console.log('Rejected');
          console.log(response);
        }
      );
  }

  getCounties(selectedState: any) {
    this.counties = [];
    this.countiesBase.forEach(element => {
      if (element['state'] === selectedState) {
        this.counties.push({
          state: element['state'],
          county: element['county'],
          name: element['name']
        });
      }
    });
  }

  getMaritalStat(): void {
    this.civils = [];
    try{
      this.api.get<ApiServiceResponse>(GET_MARSTAT, '')
      .then(
        (response: ApiServiceResponse) => {
          response.data.forEach( (e:any) => {
            this.civils.push({ value: e.pk_maritalstatus, display: e.inddesc });
          });
        },
        (response: ApiServiceResponse) => {
          console.log('Rejected');
          console.log(response);
          alert('Error cargando estados civiles.');
        }
      );
    } catch(err){
      alert('Error cargando estados civiles.');
    }
  }

  changeLang() {
    this.translate.use(this.lang);
  }
}

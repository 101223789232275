import {Component, OnInit, ViewChild} from '@angular/core';
import {CountdownComponent} from 'ngx-countdown';

const LENGTH_LONG = 5000;
const LENGTH_SHORT = 2000;
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  @ViewChild(CountdownComponent) counter: CountdownComponent;

  validationActive: boolean;
  displayModal: boolean;
  phoneNumber: any;
  navCtrl: any;
  profile: any;
  message: string;
  card: any;
  code: any;
  config = { leftTime: 120, format: 'mm:ss' };

  constructor() {}

  ngOnInit() {
    this.displayModal = false;
    this.initValues();
    if (typeof window !== 'undefined') {
      window.addEventListener(
        'signUp',
        function(e: CustomEvent) {
          this.navCtrl.current = 'phoneNumber';
          this.validationActive = false;
          this.displayModal = true;
        }.bind(this)
      );
    }
  }

  isCurrentStep(step: string): boolean {
    return this.navCtrl.current === step;
  }

  saveProfile() {
    this.validationActive = true;
    if (!this.validateForm()) {
      this.navCtrl.current = 'creditCard';
      this.validationActive = false;
    }
  }

  validateForm() {
    return (
      this.validateField(this.profile.name) ||
      this.validateField(this.profile.lastname) ||
      this.validateField(this.profile.email) ||
      this.validateField(this.profile.password) ||
      this.validateField(this.profile.repeat) ||
      this.validateField(this.profile.date) ||
      this.validateField(this.profile.gender)
    );
  }

  validateField(value: string): boolean {
    return this.validationActive && value === '';
  }

  saveCreditCard() {
    this.validationActive = true;
    if (!this.validateCreditCard()) {
      this.navCtrl.current = 'complete';
    }
  }

  validateCreditCard() {
    return (
      this.validateField(this.card.number) ||
      this.validateField(this.card.name) ||
      this.validateField(this.card.month) ||
      this.validateField(this.card.year) ||
      this.validateField(this.card.code)
    );
  }

  sendCode() {
    this.validationActive = true;
    if (!this.validatePhoneNumber()) {
      this.navCtrl.current = 'validateCode';
      this.validationActive = false;
    }
  }

  validatePhoneNumber() {
    return this.validateField(this.phoneNumber);
  }

  onFinished(event) {
    if (event.action === 'done') {
      this.code.runing = false;
    }
  }

  resendCode() {
    if (!this.code.runing) {
      this.code.runing = true;
      this.counter.restart();
    }
  }

  changePhoneNumber() {
    this.navCtrl.current = 'phoneNumber';
    this.validationActive = false;
  }

  validateCode() {
    if (this.code.value !== '') {
      this.navCtrl.current = 'completeProfile';
      this.code.error = false;
    } else {
      this.code.error = true;
    }
  }

  cancelProfile() {
    this.navCtrl.current = 'cancelProfile';
    this.navCtrl.last = 'completeProfile';
  }

  cancelCreditCard() {
    this.navCtrl.current = 'cancelCreditCard';
    this.navCtrl.last = 'creditCard';
  }

  continue() {
    this.navCtrl.current = this.navCtrl.last;
  }

  initValues() {
    this.profile = {name: '', lastname: '', email: '', password: '', repeat: '', date: '', gender: ''};
    this.card = {number: '', name: '', month: '', year: '', code: ''};
    this.code = {value: '', runing: true, error: false};
    this.navCtrl = {current: '', last: ''};
    this.phoneNumber = '';
  }

  close() {
    this.displayModal = false;
    this.initValues();
  }

  fileSelected(event: any) {
    const file: File = event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = e => {
      this.profile.image = myReader.result;
    };
    myReader.readAsDataURL(file);
  }

  getProfileImage() {
    return this.profile.image != null ? this.profile.image : '/assets/img/sing-up/profile.PNG';
  }

  uploadProfileImage() {
    document.getElementById('profileImage').click();
  }

  phoneNumberFilter(event) {
    if (this.phoneNumber !== '') {
      this.phoneNumber = this.phoneNumber.replace(/[^0-9.]/g, '');
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd, NavigationStart} from '@angular/router';
import { General } from 'src/data/general';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  pages = [
    {
      title: 'actions.go',
      url: '/'
    },
    {
      title: 'actions.discover',
      url: '/descubrir'
    },
    {
      title: 'passer card',
      url: '/passer-card'
    },
    /*{
      title: 'Bitpass',
      url: '/bitpass'
    },*/
    {
      title: 'labels.enterprises',
      url: '/enterprises'
    }
  ];

  general = General;

  active: any;
  showLinks = false;

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.active = undefined;
        this.initActivePage(event.url);
        this.validateSectionActive(event.url);
        this.showLinks = (event.url.indexOf("completeAccount") == -1);
      }
    });
  }

  initActivePage(url) {
    // tslint:disable-next-line: prefer-const
    for (let page of this.pages) {
      if (url === page.url) {
        this.active = page;
      }
    }
  }

  openPage(page: any) {
    this.active = page;
    this.router.navigate([page.url]);
    this.toggleMenu();
    this.validateSectionActive(page.url);
  }

  validateSectionActive(url) {
    if (url != null && url.includes('descubrir')) {
      setTimeout(() => {
        // tslint:disable-next-line: prefer-const
        let section = document.getElementById('upcoming');
        section.scrollIntoView({behavior: 'smooth'});
      });
    } else {
      if (typeof window !== 'undefined') {
        this.general.scroll(0, 0);
      }
    }
  }

  isActived(title: string): boolean {
    return (this.active && this.active.title === title);
  }

  toggleMenu() {
    document.getElementById('nav-mobile').classList.toggle('show-menu');
    document.getElementById('nav-shadow').classList.toggle('show-menu');
  }

  signUp() {
    // tslint:disable-next-line: prefer-const
    let c_event = new CustomEvent('signUp', {detail: 3});
    this.general.dispatchEvent(c_event);
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { SEOService } from 'src/api/metatags.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent  implements OnInit {

  headerFooter = true;
  constructor(translate: TranslateService, private router: Router, seo: SEOService) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('es');
    /* let browserLang = translate.getBrowserLang();
    browserLang = browserLang.match(/en|es/) ? browserLang : 'es';
    const userLang = browserLang;
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const html = document.querySelector('html');
    html.setAttribute('lang' , userLang || 'es');
    translate.use(userLang);
    */
    translate.use('es');
  }
  ngOnInit() {
    this.router.events
    .subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.headerFooter = ( !event.url.includes('/welcome') && !event.url.includes('/account-protection') &&
                              !event.url.includes('/change-password') && !event.url.includes('/reset-password') &&
                              !event.url.includes('/verify') && !event.url.includes('/completed'));
      }
    });
  }
}

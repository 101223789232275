import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ApiService {
    token = '';
    constructor(
        private http: HttpClient,
        private translate: TranslateService
    ) { }

    setToken(token: string): void {
        this.token = 'Bearer ' + token;
    }

    postWithHeaders<Type>(url: string, headers: HttpHeaders, body: any, err?) {
        return new Promise((resolve, reject) => {
            // tslint:disable-next-line: prefer-const
            this.http.post(url, body, { headers })
                .subscribe(
                    response => {
                        resolve(response as Type);
                    },
                    error => {
                        if (error.error) {
                          reject((!!err ? error.error : error.error.data) as Type);
                        }
                        if (error instanceof HttpErrorResponse) {     
                          reject(!!error.message);
                        }
                    }
                );
        });
    }

    patchWithHeaders<Type>(url: string, headers: HttpHeaders, body: any, err?) {
        return new Promise((resolve, reject) => {
            // tslint:disable-next-line: prefer-const
            this.http.patch(url, body, { headers })
                .subscribe(
                    response => {
                        resolve(response as Type);
                    },
                    error => {
                        if (error.error) {
                          reject((!!err ? error.error : error.error.data) as Type);
                        }
                        if (error instanceof HttpErrorResponse) {     
                          reject(!!error.message);
                        }
                    }
                );
        });
    }
    
    postFormUrlEncoded<Type>(url: string, body: string, err?) {
        return new Promise((resolve, reject) => {
            // tslint:disable-next-line: prefer-const
            let headers = new HttpHeaders();
            headers = headers.append("Content-Type", "application/x-www-form-urlencoded");
            this.http.post(url, body, { headers })
                .subscribe(
                    response => {
                        resolve(response as unknown as Type);
                    },
                    error => {
                        reject((!!err ? error.error : error.error.data) as Type);
                    }
                );
        });
    }

    post<Type>(url: string, body: any, err?) {
      return new Promise((resolve, reject) => {
          // tslint:disable-next-line: prefer-const
          let headers = this.getHeader();
          this.http.post(url, body, { headers })
              .subscribe(
                  response => {
                      resolve(response as Type);
                  },
                  error => {
                      reject((!!err ? error.error : error.error.data) as Type);
                  }
              );
      });
    }

    put<Type>(url: string, body: any) {
      return new Promise((resolve, reject) => {
          // tslint:disable-next-line: prefer-const
          let headers = this.getHeader();
          this.http.put(url, body, { headers })
              .subscribe(
                  response => {
                      resolve(response as Type);
                  },
                  error => {
                      reject(error.error as Type);
                  }
              );
      });
    }

    putWithHeaders<Type>(url: string,  headers: HttpHeaders, body: any) {
        return new Promise((resolve, reject) => {
            // tslint:disable-next-line: prefer-const
            this.http.put(url, body, { headers })
                .subscribe(
                    response => {
                        resolve(response as Type);
                    },
                    error => {
                        reject(error.error as Type);
                    }
                );
        });
    }

    postAllData<Type>(url: string, body: any) {
      return new Promise((resolve, reject) => {
          // tslint:disable-next-line: prefer-const
          let headers = this.getHeader();
          this.http.post(url, body, { headers, withCredentials: true })
              .subscribe(
                  response => {
                      resolve(response as Type);
                  },
                  error => {
                      reject(error.error as Type);
                  }
              );
      });
    }

    login<Type>(url, body, client?, token?) {
      return new Promise((resolve, reject) => {
        // tslint:disable-next-line: prefer-const
        let headers = new HttpHeaders();
        headers = headers.append('Accept-Language', 'es');
        headers = headers.append('Content-Type', 'application/json');
        if (client) { headers = headers.append('x-client-app-token', client); }
        if (token) { headers = headers.append('Authorization', token); }
        this.http.post(url, body, { headers, withCredentials: true })
          .subscribe(
            response => { resolve(response as Type); },
            error => { reject(error.error as Type); }
          );
      });
    }

    get<Type>(url: string, params: any) {
        return new Promise((resolve, reject) => {
            // tslint:disable-next-line: prefer-const
            let headers = this.getHeader();
            this.http.get(url + params, { headers })
                .subscribe(
                    response => {
                        resolve(response as Type);
                    },
                    error => {
                        reject(error.error.data as Type);
                    });
        });
    }

    getWithSimpleHeadears<Type>(url: string, params: any) {
        return new Promise((resolve, reject) => {
            this.http.get(url + params, { headers: { ...this.getSimpleHeader() } })
                .subscribe(
                    response => {
                        resolve(response as Type);
                    },
                    error => {
                        reject(error.error.data as Type);
                    });
        });
    }

    getWithHeaders<Type>(url: string, headers: HttpHeaders) {
        return new Promise((resolve, reject) => {
            // tslint:disable-next-line: prefer-const;
            this.http.get(url, { headers })
                .subscribe(
                    response => {
                        resolve(response as Type);
                    },
                    error => {
                        reject(error.error.data as Type);
                    });
        });
    }

    getSimpleHeader(): any {
        return {
            'Content-Type': 'application/json',
            'Accept-Language': this.translate.currentLang || 'es'
        };
    }

    getHeader(): HttpHeaders {
        // tslint:disable-next-line: prefer-const
        let headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        headers.set('Authorization', this.token);
        headers.set('x-client-app-token', this.token);
        headers.set('Accept-Language', this.translate.currentLang || 'es');
        return headers;
    }
}

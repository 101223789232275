import { Meta } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SEOService {
  constructor(private meta: Meta) {
    this.meta.addTags([
      {name: 'description', content: 'Con Passer puedes comprar tiquetes online, reservar hoteles y mucho más'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=5, user-scalable=0"'},
      {name: 'robots', content: 'INDEX, FOLLOW'},
      {name: 'author', content: 'Passer Technologies'},
      {name: 'keywords', content: 'Buses, Passer, Payments, Taxis, PasserCard'},
      {name: 'date', content: '2019-09-23', scheme: 'YYYY-MM-DD'},
      {name: 'url', content: 'https://passer.live/'},
      {httpEquiv: 'Content-Type', content: 'text/html'},
      {property: 'og:title', content: 'Passer'},
      {property: 'og:type', content: 'website'},
      {property: 'og:site_name', content: 'Passer'},
      {property: 'og:description', content: 'Con Passer puedes comprar tiquetes online, reservar hoteles y mucho más'},
      {property: 'og:url', content: 'https://passer.live/'},
      {property: 'og:image', content: ''},
      {property: 'twitter:card', content: 'summary'},
      {property: 'twitter:site', content: '@Passer_Oficial'},
      {property: 'twitter:creator', content: '@Passer_Oficial'},
      {charset: 'UTF-8'}
    ]);
  }
}

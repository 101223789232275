import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Validator } from '../../../data/validator';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { General } from 'src/data/general';
import { AccountService } from 'src/api/account.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  general = General;
  idpToken: string;
  idCommerce = '';

  formGroup: FormGroup;
  password: FormControl;
  confirmPassword: FormControl;

  passwordType: boolean = true;
  confirmPasswordType: boolean = true;

  translate: TranslateService;
  lang = 'es';
  isResetSuccess = false;
  message: string;
  error: boolean;
  load = false;

  constructor(
    private router: Router,
    private idpApi: AccountService,
    translate: TranslateService,
    private formBuilder: FormBuilder
  ) {
    this.translate = translate;
    this.lang = this.translate.currentLang;
    this.createControls();
  }

  ngOnInit(): void {
    const token = this.general.getHref().split('reset-password?token=');
    if (token && token.length === 2) {
      this.idpToken = token[1];
      this.load = true;
    } else {
      this.load = false;
      this.router.navigate(['/**']);
    }
  }

  createControls(): void {
    this.password = new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(16),
      Validator.patternValidator(/\d/, { hasNumber: true }),
      Validator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      Validator.patternValidator(/[a-z]/, { hasSmallCase: true }),
    ]);
    this.confirmPassword = new FormControl('', Validators.required);

    this.formGroup = this.formBuilder.group({
      password: this.password,
      confirmPassword: this.confirmPassword
    },
      { validator: Validator.confirmedValidator('password', 'confirmPassword') }
    );
  }

  submit() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.idpApi.idpPatchRecoveryPassword(
      this.idpToken,
      this.password.value,
      this.confirmPassword.value,
      btoa(`${environment.idpClientId}:${environment.idpClientSecret}`),
      (response: any) => {
        this.isResetSuccess = response.success;
        this.showSnackMessage(response.message);
      },
      (response: any) => {
        this.showSnackMessage('alerts.err_pass');
        this.isResetSuccess = false;
      }
    );
  }

  showSnackMessage(message) {
    this.message = message;
    const snackbar = document.getElementById('snackbar');
    snackbar.className = 'show';
    setTimeout(function () { snackbar.className = snackbar.className.replace('show', ''); }, 3000);
  }

  changeLang() {
    this.translate.use(this.lang);
  }

  togglePasswordType() {
    this.passwordType = !this.passwordType;
  }

  toggleConfirmPasswordType() {
    this.confirmPasswordType = !this.confirmPasswordType;
  }
}

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { countryNameOption } from 'src/data/countries.data';
import { General } from 'src/data/general';

@Component({
  selector: 'app-search-selection',
  templateUrl: './search-selection.component.html'
})
export class SearchSelectionComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  @Input() appearance = 'outline';
  @Input() classDiv: string = '';
  @Input() classError: string = '';
  @Input() selectControl: FormControl = new FormControl();
  @Input() label: string = '';
  @Input() search: string = '';
  @Input() list: any[] = [];
  @Input() listLoaded: boolean = false;
  @Input() isCountrySelect = false;

  @Output() selectedOption = new EventEmitter<any>();

  currentlanguage;
  nameCountryLabel = 'esOfficialName';
  public selectedControl: FormControl = new FormControl();
  public filteredResult: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>(); 

  constructor(private translation: TranslateService) {}

  ngOnInit(): void {
    this.currentlanguage = this.translation.currentLang;
    this.nameCountryLabel = this.currentlanguage === 'es' ? 'esOfficialName' : 'enOfficialName';
    this.filteredResult.next(this.list.slice());
    
    this.selectedControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterList();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.listLoaded?.currentValue) {
        this.filteredResult.next(this.list.slice());
    }
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  getSelectName(item: any): string {
    if (this.isCountrySelect) {
      return countryNameOption(this.currentlanguage, item);
    }

    return this.search ? item[this.search] : item;
  }

  protected setInitialValue() {
    this.filteredResult
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: any, b: any) => a && b && (this.search ? a[this.search] === b[this.search] : a === b);
      });
  }

  protected filterList() {
    if (!this.list) {
      return;
    }
    // get the search keyword
    let search = this.selectedControl.value;
    if (!search) {
      this.filteredResult.next(this.list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    search = General.deleteStressM(search);
    // filter the list
    this.filteredResult.next(
      this.list.filter(item => {
        if (this.isCountrySelect) {
          return `${(item.dial)}`.toLowerCase().indexOf(search) > -1
            || General.deleteStressM(`${(item[this.nameCountryLabel])}`)
                  .toLowerCase()
                  .indexOf(search) > -1;
        }
        return `${(this.search ? item[this.search] : item)}`.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  clickAction(): void {
    this.selectedOption.emit();
  }
}
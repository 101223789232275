import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApiServiceResponse } from './api.service.response';
import { SEND_NOTIFICATION, SUBSCRIBE } from './constants';

@Injectable()
export class EmailService {
    constructor(private apiService: ApiService) {
    }

    sendEmail(to, subject, data) {
        return this.apiService.post<ApiServiceResponse>(SEND_NOTIFICATION,
            {
                type: 'email',
                to: to,
                subject: subject,
                template: 'submitedScheduleDemo',
                data: data
            }
        );
    }

    subscribe(to, emailAddress, subscriberName) {
        return this.apiService.postAllData<ApiServiceResponse>(SUBSCRIBE,
            {
                to: to,
                emailAddress: emailAddress,
                subscriberName: subscriberName
            }
        );
    }
}
